import { Grid, Button, Paper, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import { filterBy } from '@progress/kendo-data-query';
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns';
import csv from 'csvtojson';
import FileDownload from 'js-file-download';
import React, { useCallback, useEffect, useState, useMemo } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import TableWrapperStyles from '../../assets/jss/components/TableWrapperStyles';
import { getMasterLabTestsTenant } from '../Orders/ordersSlice';
import { selectOrderableTestsList } from '../Orders/selectors';
import { genderEnums } from '../Profile/ProfileHelper';
import AppDateRangeSelector from '../Shared/Forms/KendoFields/DateRangeSelector';
import { selectCurrentTenant, selectAllTenants } from '../Shared/selectors';
import { getMyTenants } from '../Shared/sharedSlice';
import KendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';
import { initialSorting, transform, reportColumns } from './helpers/customReportListMapper';
import { previewReportHeaders, reportTypes } from './helpers/mappings';
import { clearCustomReportData, getCustomReport } from './reportsSlice';
import { selectReports, selectReportsLoading } from './selectors';

// components and helpers

function CustomReportListWrapper() {
  const classes = useStyles();

  const currentTenant = useSelector(selectCurrentTenant);
  const reports = useSelector(selectReports);
  const reportsLoading = useSelector(selectReportsLoading);
  const orderableTests = useSelector(selectOrderableTestsList);

  const [reportType, setReportType] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [sort, setSort] = useState(initialSorting.DEFAULT.field);
  const [direction, setDirection] = useState(initialSorting.DEFAULT.dir);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [loginDateRange, setLoginDateRange] = useState({
    start: null,
    end: null,
  });

  const [rows, setRows] = useState([]);
  const [tests, setTests] = useState([]);
  const [filteredTests, setFilterTests] = useState([]);
  const [noRecordsMessage, setNoRecordsMessage] = useState('');
  const allTenants = useSelector(selectAllTenants);
  const [tenantList, setTenantList] = useState([]);
  const [filter, setFilter] = useState({
    testResult: null,
    testName: '',
    gender: null,
    tenantId: [],
    accountId: currentTenant.accountId,
    fromLastLoginDate: null,
    toLastLoginDate: null,
  });
  const testResultTypes = ['NEGATIVE', 'POSITIVE', 'UNKNOWN', 'INCONCLUSIVE', 'INVALID', 'ERROR'];
  const noRecordMessage = 'No records available for selected filters. Please try again with another set of filters';
  const loginLabel = {
    startLabel: 'Last Login Start',
    endLabel: 'Last Login End',
  };
  const [createdLabel, setCreatedLabel] = useState({
    startLabel: 'Create Date Start',
    endLabel: 'Create Date End',
  });

  const dispatch = useDispatch();

  // alphabetically tenant sort
  useEffect(() => {
    if (allTenants?.items && allTenants.items.length) {
      setTenantList(sortTenantList());
    }
  }, [allTenants]);

  useEffect(() => {
    if (reportType === reportTypes.LOGIN_REPORT.id) {
      setSort(initialSorting?.[reportType]?.field || initialSorting.DEFAULT.field)
      setDirection(initialSorting?.[reportType]?.dir || initialSorting.DEFAULT.dir)
    }
  }, [reportType]);

  const sortTenantList = (selectedTenantIds = [], hideSelected = false) => {
    const items = allTenants?.items || [];
    const updatedTenantList = [...items].map((i) => ({
      ...i,
      title: i.title.trim(),
    }));

    let sortedTenantList = updatedTenantList.sort((a, b) =>
      (a.title || '').toString().localeCompare((b.title || '').toString())
    );

    if (hideSelected && selectedTenantIds.length) {
      sortedTenantList = sortedTenantList.filter((k) => !selectedTenantIds.includes(k.id));
    }

    return sortedTenantList;
  };

  // handles all table updates from table wrapper
  const handleTableUpdate = (page, pageSize, sort, direction) => {
    setPage(page);
    setPageSize(pageSize);
    setSort(sort);
    setDirection(direction);
  };

  // REQUEST CUSTOM REPORT
  const handleRequestReport = useCallback(() => {
    setPage(0);
    setNoRecordsMessage(noRecordMessage);
    dispatch(
      getCustomReport(
        currentTenant.id,
        reportType,
        dateRange.start,
        dateRange.end,
        page,
        pageSize,
        sort,
        direction.toUpperCase(),
        filter,
        null
      )
    );
  }, [reportType, dateRange, currentTenant, page, pageSize, sort, direction, filter]);

  /**
   * Return formatted column structure for Kendo Table Wrapper
   * @param reportType
   * @returns {{id: string, label: string, show: boolean, sortable: boolean, filter?: boolean, minWidth: number},}
   */
  const columnList = useMemo(() => reportColumns[reportType] || [], [reportType]);

  /**
   * Return sorted array updated via Kendo Table Wrapper sort and direction
   *
   * @returns array
   */
  const sortResults = (values) => {
    let sorter = [...values];

    if (reportType === reportTypes.LOGIN_REPORT.id) {
      sorter = transformDate(values, 'Log-in Time Stamp', initialSorting[reportType].field)
    }

    if (direction === 'desc') {
      sorter.sort((a, b) => (b[sort]?.toUpperCase() > a[sort]?.toUpperCase() ? 1 : -1));
    } else if (direction === 'asc') {
      sorter.sort((a, b) => (a[sort]?.toUpperCase() > b[sort]?.toUpperCase() ? 1 : -1));
    }
    return sorter;
  };

  const transformDate = (data, columnDateName, newColumnName) => {
    let res = [...data]
    if (data.length && columnDateName && newColumnName) {
      res = res.map(i => {
          let date = i[columnDateName].replace('AM', ' AM')
          date = date.replace('PM', ' PM')
          return {
            ...i,
            [newColumnName]: utils.formatToUnixTime(date)
          }
        }
      )
    }

    return res
  }

  // Sort the results on a change in direction or sort

  useEffect(() => {
    if (sort) {
      setRows(sortResults(rows));
    }
  }, [sort, direction]);

  let data = {
    total: (rows && rows.length) || 0,
    page,
    pageSize,
    items: rows || [],
  };

  useEffect(() => {
    if (orderableTests) {
      setTests(
        orderableTests &&
        orderableTests.map((ot) => ({
          ...ot,
          fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
        }))
      );
      setFilterTests(
        orderableTests &&
        orderableTests.map((ot) => ({
          ...ot,
          fullName: `${ot.vendorName} / ${ot.longName ? ot.longName : ot.shortName}`,
        }))
      );
    } else {
      dispatch(getMasterLabTestsTenant('orderableTests', currentTenant.id));
    }
    if (!allTenants) {
      dispatch(getMyTenants());
    }
  }, [orderableTests]);

  // Date Selection
  const handleChangeTimePeriod = (dateRangeValue) => {
    dispatch(clearCustomReportData());
    setRows([]);
    const dateRangeObject = {
      start: formatDate(dateRangeValue.start),
      end: formatDate(dateRangeValue.end),
    };
    setDateRange(dateRangeObject);
  };

  const formatDate = (date) => {
    if (date) {
      return utils.formatDateNoLocale(date);
    }
    return null;
  };

  const handleChangeLoginDate = (dateRangeValue) => {
    dispatch(clearCustomReportData());
    setRows([]);
    const dateRangeObject = {
      start: formatDate(dateRangeValue.start),
      end: formatDate(dateRangeValue.end),
    };
    setLoginDateRange(dateRangeObject);
    setFilter({
      ...filter,
      fromLastLoginDate: dateRangeObject.start,
      toLastLoginDate: dateRangeObject.end,
    });
  };

  const getHeaders = useCallback(() => {
    if (reports) {
      return new Promise((resolve) => {
        csv({
          delimiter: [','],
          fork: true,
        })
          .on('header', (headers) => {
            resolve(headers);
          })
          .fromString(reports);
      });
    }
  }, [reports]);

  const parseCSVString = useCallback(
    (parsedHeaders) => {
      if (reports) {
        const getHeaders = () => {
          if (parsedHeaders && parsedHeaders.length) return parsedHeaders;
          if (reportType) return previewReportHeaders[reportType];
          return previewReportHeaders['User Report'];
        };
        return new Promise((resolve) => {
          csv({
            noheader: true,
            // output: "csv",
            delimiter: [','],
            fork: true,
            headers: getHeaders(),
          })
            .preFileLine((fileLineString) => {
              let countQuotesInLine = (fileLineString.match(/"/g) || []).length;

              // if unpaired count of quotes in line - delete it
              if (countQuotesInLine % 2) {
                fileLineString = '';
              }
              return fileLineString;
            })
            .fromString(reports)
            .then((csvRow) => {
              resolve(csvRow);
            });
        });
      }
    },
    [reports]
  );

  useEffect(() => {
    (async () => {
      const parsedHeaders = await getHeaders();
      const parsedData = await parseCSVString(parsedHeaders);
      if (parsedData) {
        parsedData.shift();
        setRows(sortResults(parsedData));
      }
    })();
  }, [getHeaders, parseCSVString]);

  const handleReportTypeChange = (e) => {
    dispatch(clearCustomReportData());
    setRows([]);
    setNoRecordsMessage('');
    setFilter({
      testResult: null,
      testName: '',
      gender: null,
      tenantId: [],
      accountId: currentTenant.accountId,
      fromLastLoginDate: null,
      toLastLoginDate: null,
    });

    if (e?.value?.id) {
      setReportType(e?.value?.id);
      setCreatedLabel(reportTypes[e?.value?.id].labels);
      setFilter({ ...filter, tenantId: [currentTenant.id] });
    } else {
      setReportType(null);
    }
  };

  const handleTestResultChange = (e) => {
    dispatch(clearCustomReportData());
    setRows([]);
    setFilter({ ...filter, testResult: e.value });
  };

  const handleTestNameChange = (e) => {
    dispatch(clearCustomReportData());
    setRows([]);
    setFilter({ ...filter, testName: e.value?.labTestOrderableId });
  };

  const handleGenderChange = (e) => {
    dispatch(clearCustomReportData());
    setRows([]);
    setFilter({ ...filter, gender: e.value?.code });
  };

  const handleTestNameFilter = (e) => {
    dispatch(clearCustomReportData());
    setRows([]);
    if (e.filter.value) setFilterTests(tests.filter((x) => x.fullName.toLowerCase().includes(e.filter.value)));
  };

  const handleTenantChange = (e) => {
    dispatch(clearCustomReportData());
    const selectedTenantIds = e.value.map((x) => x.id);
    setFilter({ ...filter, tenantId: selectedTenantIds });
    setRows([]);
    setTenantList(sortTenantList(selectedTenantIds, true));
  };

  const onFilterChange = (event) => {
    const defaultTenantList = sortTenantList(filter.tenantId, true);
    setTenantList(filterBy(defaultTenantList.slice(), event.filter));
  };

  const downloadCustomReport = () => {
    if (reportType == reportTypes.ACTIVE_USER_REPORT.id) {
      FileDownload(
        reports,
        `${utils.removeUnderscoresTitleCase(reportType).replace(' ', '')}_${utils.formatDateReport(new Date())}.csv`
      );
    } else {
      FileDownload(reports, `${reportType}-${dateRange.start}-to-${dateRange.end}.csv`);
    }
  };

  return (
    <div>
      <Paper className={classes.paper} style={{ borderRadius: '20px' }} elevation={0}>
        <Grid item container justify={'center'}>
          <Grid item xs={6} md={3} lg={3} style={{ margin: '0 20px 10px 0' }}>
            <ComboBox
              placeholder={'Report Type'}
              data={Object.values(reportTypes)}
              textField="title"
              dataItemKey="id"
              style={{ width: '100%' }}
              onChange={handleReportTypeChange}
              disabled={reportsLoading}
            />
          </Grid>
          {reportType != reportTypes.ACTIVE_USER_REPORT.id && (
            <Grid item xs={12} md={6} lg={5} align="center" style={{ margin: '0 20px 10px 0' }}>
              <AppDateRangeSelector
                maxEndDate={Date.now()}
                onDateRangeChange={handleChangeTimePeriod}
                dateRange={dateRange}
                disabled={reportsLoading}
                inputLabel={createdLabel}
                dateButtonsPos={'end'}
              />
            </Grid>
          )}
          {reportType == reportTypes.ACTIVE_USER_REPORT.id && (
            <Grid item xs={12} md={6} lg={5} align="center" style={{ margin: '0 20px 10px 0' }}>
              <AppDateRangeSelector
                maxEndDate={Date.now()}
                onDateRangeChange={handleChangeLoginDate}
                dateRange={loginDateRange}
                disabled={reportsLoading}
                inputLabel={loginLabel}
                dateButtonsPos={'end'}
              />
            </Grid>
          )}
          <Grid item xs={12} md={2} lg={3} align="right" style={{ margin: '0 20px 10px 0' }}>
            <Box className={classes.customBox}>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={handleRequestReport}
                disableElevation
                elevation={0}
                disabled={!reportType || reportsLoading}
              >
                Generate Custom Report
              </Button>
              <IconButton className={classes.iconButton} onClick={downloadCustomReport} disabled={!rows.length}>
                <CloudDownload/>
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {reportType == reportTypes.LABTEST_REPORT.id && (
          <Grid item container justify={'center'}>
            <Grid item xs={6} md={3} lg={3} style={{ margin: '0 20px 10px 0' }}>
              <ComboBox
                placeholder={'User Gender'}
                data={genderEnums}
                allowCustom={false}
                style={{ width: '100%' }}
                onChange={handleGenderChange}
                disabled={reportsLoading}
                textField="name"
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3} style={{ margin: '0 20px 10px 0' }}>
              <ComboBox
                placeholder={'Test Result'}
                data={testResultTypes}
                allowCustom={false}
                style={{ width: '100%' }}
                onChange={handleTestResultChange}
                disabled={reportsLoading}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={3} style={{ margin: '0 20px 10px 0' }}>
              <ComboBox
                placeholder={'Test Name'}
                data={filteredTests || []}
                allowCustom={false}
                filterable
                onFilterChange={handleTestNameFilter}
                style={{ width: '100%' }}
                onChange={handleTestNameChange}
                disabled={reportsLoading}
                textField="fullName"
              />
            </Grid>
          </Grid>
        )}
        {reportType == reportTypes.ACTIVE_USER_REPORT.id && (
          <Grid item container justify={'center'}>
            <Grid item xs={12} md={6} lg={5} align="center" style={{ margin: '0 20px 10px 0' }}>
              <AppDateRangeSelector
                maxEndDate={Date.now()}
                onDateRangeChange={handleChangeTimePeriod}
                dateRange={dateRange}
                disabled={reportsLoading}
                inputLabel={createdLabel}
                dateButtonsPos={'end'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5} style={{ margin: '0 20px 10px 0' }}>
              <MultiSelect
                placeholder={'Tenants'}
                data={tenantList || []}
                allowCustom={false}
                autoClose={false}
                style={{ width: '100%' }}
                onChange={handleTenantChange}
                disabled={reportsLoading}
                textField="title"
                filterable
                onFilterChange={onFilterChange}
              />
            </Grid>
          </Grid>
        )}
        {!reportType && (
          <Grid item container justify={'center'}>
            {'No report criteria selected.'}
          </Grid>
        )}
        {noRecordsMessage == noRecordMessage && (
          <KendoTableWrapper
            data={reportType ? transform(data, page, pageSize) : []}
            parent={'custom-report'}
            initialSort={sort}
            initialDir={direction}
            showButtons={false}
            showSearchbar={false}
            columns={columnList}
            currentTenant={currentTenant}
            loading={reportsLoading}
            onTableUpdate={handleTableUpdate}
            paginationPages={[20, 50, 100]}
            suppliedPageSize={pageSize}
            noRecordsMessage={noRecordsMessage}
          />
        )}
      </Paper>
    </div>
  );
}

const useStyles = makeStyles(TableWrapperStyles);

export default CustomReportListWrapper;
