import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from "@material-ui/icons/Close";
import { PASSWORD_LENGTH } from "./helpers/mappings";

export const PasswordValidation = ({
                                     password,
                                     alwaysShow,
                                   }) => {
  const classes = usePasswordValidationStyles()

  const isValidLength = password?.length >= PASSWORD_LENGTH
  const isValidLower = /[a-z]/.test(password)
  const isValidUpper = /[A-Z]/.test(password)
  const isValidDigit = /[0-9]/.test(password)
  const isValidSpecial = /([^A-Za-z0-9])/.test(password)

  if (!password && !alwaysShow) return null

  return (
    <Box className={classes.passwordSection}>
      <ValidationRule
        isValid={isValidLength}
        label={"At least 8 characters"}
      />
      <ValidationRule isValid={isValidUpper} label={"1 Uppercase letter"}/>
      <ValidationRule isValid={isValidDigit} label={"1 Number"}/>
      <ValidationRule
        isValid={isValidLower}
        label={"1 Lowercase letter"}
      />
      <ValidationRule
        isValid={isValidSpecial}
        label={"1 Special character"}
      />
    </Box>
  )
}
const usePasswordValidationStyles = makeStyles((theme) => ({
  passwordSection: {
    marginTop: '7px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}))

const ValidationRule = ({ isValid, label }) => {
  const classes = useValidRuleStyles()

  return (
    <Box className={classes.passwordInformation}>
      <Box component="span">
        {!isValid ? (
          <CloseIcon
            className={`${classes.checkIcon} ${classes.greyIcon}`}
          />
        ) : (
          <CheckRoundedIcon
            className={classes.checkIcon}
          />
        )}
      </Box>
      <Box component="span" className={`${classes.passInfoText} ${isValid ? classes.passVerifiedText : ''}`}>
        {label}
      </Box>
    </Box>
  )
}
const useValidRuleStyles = makeStyles((_theme) => ({
  passwordInformation: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '18px',
  },
  checkIcon: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    // color: '#003C71',
    color: '#009104',
  },
  greyIcon: {
    color: '#CCCCCC !important',
  },
  passInfoText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: 0.15,
    color: '#757575',
  },
  passVerifiedText: {
    color: '#009104!important',
  },
}))
