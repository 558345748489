import * as Yup from 'yup';
import {
  isValidPasswordDigit,
  isValidPasswordLength,
  isValidPasswordLower, isValidPasswordSpecial,
  isValidPasswordUpper
} from "./helpers/helpers";

export function loginSchema() {
  return Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
  });
}

export function resetPasSchema() {
  return Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
  });
}

export function passCodeSchema() {
  return Yup.object().shape({
    password: Yup.string()
      .required('Required')
      .test('valid-length', "One or more password criteria have not been met.", (value) =>
        isValidPasswordLength(value || '')
      )
      .test('valid-upper', "One or more password criteria have not been met.", (value) =>
        isValidPasswordUpper(value || '')
      )
      .test('valid-lower', "One or more password criteria have not been met.", (value) =>
        isValidPasswordLower(value || '')
      )
      .test('valid-digit', "One or more password criteria have not been met.", (value) =>
        isValidPasswordDigit(value || '')
      )
      .test('valid-special', "One or more password criteria have not been met.", (value) =>
        isValidPasswordSpecial(value || '')
      )
    ,
    confirmationCode: Yup.string()
      .required('Required')
    // .min(6, "Minimum length is 6 numbers")
    // .max(6, "Maximum length is 6 numbers")
    ,
  });
}
