import { Box, Button } from '@material-ui/core';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showNotification } from '../../Notifications/notificationSlice';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import { selectUserDetails } from '../../Users/selectors';
import { getUser } from '../../Users/usersSlice';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { selectWorkflowNotificationLoading } from '../slice/selector';
import { consultWorkflow, resetWorkflowNotification } from '../slice/steadyMDSimulatorSlice';

const consultIdField = {
  type: 'text',
  name: 'consultId',
  label: 'Consult ID',
  readOnly: false,
  required: true,
};

function ConsultWorkflowSimulator() {
  const dispatch = useDispatch();
  const params = useParams();
  const [consultId, setConsultId] = useState('');

  const notificationLoading = useSelector(selectWorkflowNotificationLoading);

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const isMounted = useRef(false);

  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };
    getUserData();

    isMounted.current = true;
  }, [userId]);
  /* END - getting userDetails for _id */

  const [error, setError] = useState({});

  const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleIdChange = (e) => {
    /* Clear Error */
    const clearedErr = { ...error };
    delete clearedErr['consultId'];
    setError({ ...clearedErr });
    setConsultId(e.target.value);
  };

  useEffect(() => {
    if (notificationLoading === 'fulfilled' || notificationLoading === 'rejected') {
      dispatch(resetWorkflowNotification());
    }
  }, [notificationLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) return null;

    const userIdPayload = userDetails?._id ?? null;
    if (!userIdPayload) return;

    const payload = {
      formData: {
        id: consultId,
        accountID: accountId,
        tenantID: tenantId,
        user: userId,
      },
    };

    try {
      const result = await dispatch(consultWorkflow(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetWorkflowNotification());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetWorkflowNotification());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br />Executed successfully`, 'success'));
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
      dispatch(resetWorkflowNotification());
    }
  };

  const validateInputs = () => {
    let hasError = false;

    if (!consultId) {
      setError((err) => ({
        ...err,
        consultId: {
          message: 'Consult ID is required.',
        },
      }));
      hasError = true;
    }

    return hasError;
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {(notificationLoading === 'pending' || !isMounted.current) && <OverlayLoading />}
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box textAlign={'center'}>
          <TextFieldInput field={consultIdField} value={consultId} handleChange={handleIdChange} />
          {error?.consultId && <Error> {error?.consultId?.message} </Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ConsultWorkflowSimulator;
