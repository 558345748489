/* eslint-disable no-return-assign */
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ContentStyles from '../../../assets/jss/components/ContentStyles';
import DragExpansionWrapperStyles from '../../../assets/jss/components/DragExpansionWrapperStyles';
import { showConfirmation } from '../../Confirmation/confirmationSlice';
import { updateVideos } from '../../Content/contentSlice';
import { selectVideos } from '../../Content/selectors';
import LanguageMenu from '../../LanguageMenu/LanguageMenu';
import { setAllowChange } from '../../LanguageMenu/languageSlice';
import { selectLanguage } from '../../LanguageMenu/selectors';
import { showNotification } from '../../Notifications/notificationSlice';
import utils from '../../Shared/Utils/utils';
import { SettingsConfirmImportDialog } from '../SettingsConfirmImportDialog';
import {
  archiveVerbiageConsent,
  previewVerbiage,
  updateFaqOrderedList,
  updateFaq,
  updateVerbiage,
  getFaqs,
  addFaq,
  importFaq,
} from '../settingsSlice';

import ExpansionSkeletonLoading from './ExpansionSkeletonLoading';
import SettingsExpansionPanel from './SettingsExpansionPanel';
import VerbiageCreateDialog from './VerbiageCreateDialog';
import VerbiagePreviewDialog from './VerbiagePreviewDialog';

// const showLanguageMenu = ["faq", "videos", "verbiage"];
const showLanguageMenu = [];

export function DragExpansionWrapper(props) {
  const {
    classes,
    currentTenant,
    dataLoading,
    submitting,
    deleting,
    data,
    updateId,
    type,
    faqType,
    verbiageDefinitions,
    verbiageType,
    tabType,
  } = props;

  const contentClasses = useContentStyles();
  const nestedClasses = useNestedStyles();

  const language = useSelector(selectLanguage);
  const videos = useSelector(selectVideos);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [panelData, setPanelData] = useState('');
  const [editing, setEditing] = useState('');
  const [editingType, setEditingType] = useState('');
  const [loading, setLoading] = useState(true);
  const [archiveConsentLoading, setArchiveConsentLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [availableDefinitions, setAvailableDefinitions] = useState([]);
  const [allDefsPairs, setAllDefsPairs] = useState([]);
  const [snackbarDefinitions, setSnackbarDefinitions] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery('(max-width: 960px)');

  const verbiageTypeVal = verbiageType === 'documents';
  const [countActiveVerbiages, setCountActiveVerbiages] = useState(0);

  const bottomRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      dispatch(setAllowChange(true));
      setPanelData(data);
    } else {
      setPanelData('');
    }
  }, [data]);

  useEffect(() => {
    if (data?.length !== panelData?.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data, panelData]);

  useEffect(() => {
    setEditing('');
  }, [tabType]);

  // check what verbiage definitions are being used or not in case of user add for dropdown selection of unused definitions
  useEffect(() => {
    if (verbiageDefinitions && panelData) {
      const allDefs = [];
      const allPairs = {};
      const usedDefs = [];
      const snackbarDefs = [];
      let updatedCount = 0;
      const defEntries = Object.entries(verbiageDefinitions);
      defEntries.forEach((q) => {
        if (q[1].document === verbiageTypeVal) {
          allDefs.push(q[1].title);
          allPairs[q[1].title] = q[0];
        }
        // filter out all HomeScreenSnackBar subtypes for action rendering
        if (q[1].subtype === 'HomeScreenSnackBar') {
          snackbarDefs.push(q[1].title);
        }
      });

      panelData.forEach((q) => {
        usedDefs.push(q.title);
        if (verbiageTypeVal === q.document) updatedCount++;
      });

      // filter out available definitions for the dropdown list in new verbiage creation
      const availableDefs = allDefs.filter((val) => !usedDefs.includes(val));

      // set all verbiage states to use in SettingsExpansionPanel
      setAvailableDefinitions(availableDefs);
      setAllDefsPairs(allPairs);
      setCountActiveVerbiages(updatedCount);
      setSnackbarDefinitions(snackbarDefs);
    }
  }, [verbiageDefinitions, panelData, verbiageTypeVal]);

  // expand one panel at a time to view answer to question
  const handleExpansionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  // adds new question object to end of array, opens that panel and sets to editing
  const handleAddNew = () => {
    let newSequenceNo;
    let newData;
    if (type === 'faq') {
      newSequenceNo = data ? data.length : 0;
      newData = {
        sequenceNo: newSequenceNo,
        question: '',
        answer: '',
      };
      dispatch(setAllowChange(false));
    } else if (type === 'videos') {
      const parentPath = window.location.pathname.match(/\/$/)
        ? window.location.pathname
        : `${window.location.pathname}/`;
      dispatch(setAllowChange(false));
      navigate(`${parentPath}create`);
    } else if (type === 'verbiage') {
      const splitUrl = window.location.href.split('/');
      const verbiageType = splitUrl[splitUrl.length - 1];
      newSequenceNo = panelData.length;
      newData = {
        title: '',
        key: '',
        value: '',
        document: verbiageTypeVal,
        needConsent: false,
        confirmationConsentBtnLabel: '',
        type: verbiageType === 'screens' ? 'ORDINAL' : 'LEGAL_NOTICE',
        optional: false,
      };
      dispatch(setAllowChange(false));
    }
    setPanelData(data ? data.concat(newData) : [newData]);
    setExpandedPanel(`panel${newSequenceNo}`);
    setEditing(newSequenceNo);
    setEditingType('new');
  };

  const handleEditing = async (sequenceNo, type) => {
    setEditing(sequenceNo);
    setEditingType(type);
  };

  const handleCancel = (updateData) => {
    setPanelData(updateData);
  };

  // handles removal of object in faq array and saves updated items
  const handleDelete = (q) => {
    setLoading(false);
    if (type === 'faq') {
      dispatch(
        showConfirmation(
          'Confirm',
          'Are you sure you want to delete this question?',
          'faqDelete',
          currentTenant.id,
          q.id,
          null,
          async () => dispatch(getFaqs(currentTenant.id, faqType)),
          null,
          currentTenant.accountId
        )
      );
    } else if (type === 'videos') {
      const updatedVideos = data.filter((item) => item !== q);
      dispatch(
        showConfirmation(
          'Confirm',
          'Are you sure you want to delete this video?',
          'videoDelete',
          currentTenant.id,
          q,
          updatedVideos,
          null,
          null,
          currentTenant.accountId
        )
      );
    } else if (type === 'verbiage') {
      dispatch(
        showConfirmation(
          'Confirm',
          'Are you sure you want to delete this verbiage?',
          'verbiageDelete',
          currentTenant.id,
          q.key,
          null,
          null,
          null,
          currentTenant.accountId
        )
      );
    }
  };

  // handles save of any changes to question/answer
  const handleSave = async (updatedData, isImport) => {
    setLoading(false);
    setPanelData(updatedData);
    const saveData = {
      id: currentTenant.id,
      items: updatedData,
      tenant: currentTenant.id,
      _id: updateId,
      language,
    };
    if (type === 'faq') {
      if (editingType === 'new') {
        await dispatch(addFaq(currentTenant.id, saveData.items[editing], faqType));
      }
      if (editingType === 'update') {
        await dispatch(updateFaq(currentTenant.id, saveData.items[editing], faqType));
      }
      if (isImport) {
        await dispatch(importFaq(currentTenant.id, saveData.items, faqType));
      }
      await dispatch(getFaqs(currentTenant.id, faqType));
    } else if (type === 'verbiage') {
      dispatch(updateVerbiage(currentTenant.accountId, currentTenant.id, updatedData));
    }
    dispatch(setAllowChange(true));
  };

  const handleArchiveConsent = async (verbiageKey) => {
    if (type === 'verbiage' && verbiageKey) {
      setArchiveConsentLoading(true);
      await dispatch(archiveVerbiageConsent(currentTenant.id, verbiageKey));
      setArchiveConsentLoading(false);
    }
  };

  // handles copy of all FAQs to clipboard
  const handleCopy = () => {
    const jsonString = JSON.stringify(data);
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        dispatch(showNotification('Successfully copied to clipboard', 'success'));
      })
      .catch((err) => {
        dispatch(showNotification(err.message, 'error'));
      });
  };

  // handles import of FAQs from clipboard
  const handleImport = () => {
    setOpen(false);
    navigator.clipboard
      .readText()
      .then(async (text) => {
        // `text` contains the text read from the clipboard
        const parsedJson = JSON.parse(text);
        if (type === 'faq' && ((!parsedJson[0].sequenceNo && parsedJson[0].sequenceNo !== 0) || !parsedJson[0].question || !parsedJson[0].answer)) {
          dispatch(
            showNotification(
              'This is not valid help center formatting. Please try to copy the desired questions again.',
              'error'
            )
          );
        } else if (type === 'verbiage' && (!parsedJson[0].key || !parsedJson[0].value || !parsedJson[0].title)) {
          dispatch(
            showNotification(
              'This is not valid verbiage formatting. Please try to copy the desired verbiage again.',
              'error'
            )
          );
        } else {
          // dispatch(showNotification('Successfully imported.', 'success'));
          await handleSave(parsedJson, true);
        }
      })
      .catch((err) => {
        // maybe user didn't grant access to read from clipboard, not valid json, or not valid template format
        if (err.message.includes('JSON')) {
          dispatch(
            showNotification(
              `This is not valid ${type === 'faq' ? 'help center' : type} formatting. Please try to copy the desired ${
                type === 'faq' ? 'questions' : type
              } again.`,
              'error'
            )
          );
        } else {
          dispatch(showNotification(err.message, 'error'));
        }
      });
  };

  // handles preview of verbiage
  const handlePreview = (updatedData) => {
    dispatch(previewVerbiage(currentTenant.id, updatedData)).then((response) => {
      setOpenPreview(true);
      setPreviewData(response);
    });
  };

  // mobile reordering
  const handleMoveDown = async (q) => {
    setLoading(false);
    let newData;
    let newExpandedPanel = expandedPanel;

    if (type === 'faq') {
      newData = reorder(data, q.sequenceNo, q.sequenceNo + 1);
      newData.map((faq, i) => {
        if (expandedPanel) {
          newExpandedPanel = `panel${q.sequenceNo + 1}`
        }
        return (newData[i].sequenceNo = i);
      });
    }

    if (type === 'faq') {
      await dispatch(updateFaqOrderedList(currentTenant.id, newData, faqType));
      setExpandedPanel(newExpandedPanel);
    } else if (type === 'verbiage') {
      await dispatch(updateVerbiage(currentTenant.accountId, currentTenant.id, newData));
    }
  };

  const handleMoveUp = async (q) => {
    setLoading(false);
    let newData;
    let newExpandedPanel = expandedPanel;

    if (type === 'faq') {
      newData = reorder(data, q.sequenceNo, q.sequenceNo - 1);
      newData.map((data, i) => {
        if (expandedPanel) {
          newExpandedPanel = `panel${q.sequenceNo - 1}`
        }
        return (newData[i].sequenceNo = i);
      });
    }

    if (type === 'faq') {
      await dispatch(updateFaqOrderedList(currentTenant.id, newData, faqType));
      setExpandedPanel(newExpandedPanel);
    } else if (type === 'verbiage') {
      await dispatch(updateVerbiage(currentTenant.accountId, currentTenant, newData));
    }
  };

  // ======== DRAG AND DROP REORDERING FUNCTIONALITY ======== //
  // fired when reordering with drag and drop
  const onDragEnd = (result) => {
    const { destination, source } = result;
    setLoading(false);

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const newData = reorder(data, result.source.index, result.destination.index);

    // update sequence number to match reordered index, if panel open, set expanded panel to updated destination
    if (type === 'faq' || type === 'videos') {
      newData.map((data, i) => {
        if (data.sequenceNo === i + 1) return null;
        if (expandedPanel) {
          setExpandedPanel(`panel${result.destination.index + 1}`);
        }
        return (newData[i].sequenceNo = i + 1);
      });
    }

    setPanelData(newData);
    const saveData = {
      id: currentTenant.id,
      items: newData,
    };
    if (type === 'faq') {
      dispatch(updateFaq(currentTenant.id, saveData));
    } else if (type === 'verbiage') {
      dispatch(updateVerbiage(currentTenant.accountId, currentTenant.id, newData));
    } else if (type === 'videos') {
      dispatch(updateVideos(currentTenant.id, newData));
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = JSON.parse(JSON.stringify(Array.from(list)));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const capitalize = (value) => {
    return value && value.charAt(0).toUpperCase() + value.slice(1);
  };

  const getQuestionType = () => {
    if (type === 'faq') return 'Questions';
    if (type === 'videos') return 'Videos';
    return utils.snakeToCamel(tabType, ' ').slice(0, -1);
  };

  if (!currentTenant) return <div/>;
  if ((dataLoading && loading) || (videos && videos.loading)) return <ExpansionSkeletonLoading/>;

  return (
    <Grid>
      {showLanguageMenu.includes(type) ? (
        <div
          className={isMobile ? classes.languageWrapperMobile : contentClasses.languageWrapper}
          style={{ paddingTop: 15 }}
        >
          <LanguageMenu/>
        </div>
      ) : null}

      <Grid container justifyContent="center" style={{ margin: '40px 0 40px 0px' }}>
        {panelData && (
          <Grid item xs={12} className={classes.mobileFAQButton}>
            <Button
              className={nestedClasses.button}
              variant="contained"
              color="primary"
              onClick={handleAddNew}
              disabled={typeof editing === 'number'}
            >
              Add New {type}
            </Button>
          </Grid>
        )}
        <Grid item md={10} xs={12} data-testid="dndComponent">
          {((type === 'faq' || type === 'videos') && !panelData.length && !editing) ||
          (type === 'verbiage' && !countActiveVerbiages) ? (
            <Typography align="center" variant="subtitle1">
              No {capitalize(verbiageType)} {type === 'verbiage' ? 'Verbiage' : utils.capitalizeString(type)} Created
            </Typography>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="form-1">
                {(provided) => (
                  <Grid container innerRef={provided.innerRef} {...provided.droppableProps}>
                    {panelData &&
                    panelData.length &&
                    panelData.map((q, i) => {
                      if (type === 'verbiage' && q.document !== verbiageTypeVal) return null;
                      return (
                        <Draggable
                          key={q.id || q.question || q.key || `${q.networkID}_${i}`}
                          draggableId={`draggable${q.question || q.key || i}`}
                          index={i}
                          isDragDisabled={editing === q.sequenceNo || editing === i || !matches}
                        >
                          {(provided) => (
                            <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              innerRef={provided.innerRef}
                              className={classes.hoverParent}
                            >
                              <Grid item xs={false}>
                                {!editing && matches && <DragIndicatorIcon className={classes.hoverChild}/>}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={11}
                                style={{
                                  padding:
                                    expandedPanel === `panel${q.sequenceNo || i}` ? '10px 0 10px' : '1px 0 1px',
                                }}
                              >
                                <SettingsExpansionPanel
                                  q={q}
                                  identifier={q.sequenceNo || i}
                                  title={q.question || q.title}
                                  content={q.answer || q.value}
                                  actions={q.actions}
                                  data={panelData}
                                  editing={editing}
                                  editingType={editingType}
                                  submitting={submitting}
                                  deleting={deleting}
                                  expandedPanel={expandedPanel}
                                  type={type}
                                  matches={matches}
                                  verbiageDefinitions={verbiageDefinitions}
                                  availableDefinitions={availableDefinitions}
                                  allDefsPairs={allDefsPairs}
                                  verbiageType={verbiageType}
                                  onHandleSave={handleSave}
                                  onHandleDelete={handleDelete}
                                  handleExpansionChange={handleExpansionChange}
                                  onHandleCancel={handleCancel}
                                  onHandleArchiveConsent={handleArchiveConsent}
                                  onHandleEditing={handleEditing}
                                  onHandleMoveDown={handleMoveDown}
                                  onHandleMoveUp={handleMoveUp}
                                  onHandlePreview={handlePreview}
                                  archiveConsentLoading={archiveConsentLoading}
                                  snackbarDefinitions={snackbarDefinitions}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          )}
          <div ref={bottomRef}/>
        </Grid>
        <Grid item md={2} className={classes.desktopFAQButton}>
          <Grid container spacing={2} className={classes.sticky}>
            {type === 'verbiage' && (
              <Grid item xs={12}>
                <Button
                  className={nestedClasses.button}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenCreate(true)}
                  style={{ width: '100%' }}
                  disableElevation
                  disabled={
                    typeof editing === 'number' ||
                    (availableDefinitions.length === 0 && verbiageType === 'static') ||
                    submitting ||
                    deleting
                  }
                >
                  Add New Definition
                </Button>
              </Grid>
            )}
            <Tooltip
              title={availableDefinitions.length === 0 && verbiageType === 'static' ? 'All Definitions Active' : ''}
              placement="top"
            >
              <Grid item xs={12}>
                <Button
                  className={nestedClasses.button}
                  variant="contained"
                  color="primary"
                  onClick={handleAddNew}
                  style={{ width: '100%' }}
                  disableElevation
                  disabled={
                    typeof editing === 'number' ||
                    (availableDefinitions.length === 0 && verbiageType === 'static') ||
                    submitting ||
                    deleting
                  }
                >
                  Add New {getQuestionType()}
                </Button>
              </Grid>
            </Tooltip>
            {type !== 'videos' && (
              <Grid item xs={12}>
                <Button
                  className={nestedClasses.button}
                  variant="outlined"
                  color="primary"
                  onClick={handleCopy}
                  style={{ width: '100%' }}
                  disableElevation
                  disabled={
                    typeof editing === 'number' ||
                    (verbiageType && !panelData.length) ||
                    !panelData.length ||
                    submitting ||
                    deleting
                  }
                >
                  Copy All {type === 'faq' ? 'Questions' : utils.snakeToCamel(tabType, ' ')}
                </Button>
              </Grid>
            )}
            {type !== 'videos' && (
              <Grid item xs={12}>
                <Button
                  className={nestedClasses.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(true)}
                  style={{ width: '100%' }}
                  disableElevation
                  disabled={typeof editing === 'number' || submitting || deleting}
                >
                  Import {type === 'faq' ? 'Questions' : utils.snakeToCamel(tabType, ' ')}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <SettingsConfirmImportDialog
          open={open}
          handleImport={handleImport}
          title={`Import ${type === 'faq' ? 'Help Center Questions' : utils.capitalizeString(type)}`}
          message={`Are you sure you want to import the copied ${type === 'faq' ? 'questions' : type}?
          Your current ${type === 'faq' ? 'questions' : type} will be overwritten.`}
          closeImportDialog={() => setOpen(false)}
        />
        <VerbiagePreviewDialog open={openPreview} handleClose={() => setOpenPreview(false)} previewData={previewData}/>
        <VerbiageCreateDialog
          open={openCreate}
          handleClose={() => setOpenCreate(false)}
          currentTenant={currentTenant}
          language={language}
        />
      </Grid>
    </Grid>
  );
}

const useContentStyles = makeStyles(ContentStyles);
const useNestedStyles = makeStyles({
  button: {
    borderRadius: 8,
    border: '2px solid #2e5266!important',
    '&:hover': {
      border: '2px solid #2e5266!important',
    },
  },
});

export default withStyles(DragExpansionWrapperStyles)(DragExpansionWrapper);
