import SelectionHeaderCell from '../SelectionHeaderCell';
import UploadActionCell from '../UploadActionCell';
import UserComplainceCell from '../UserComplainceCell';
import UsersActionsCellWrapper from '../UsersActionsCellWrapper';
import UsersParentCell from '../UsersParentCell';
// import {
//   genderMappings,
//   lastTestResultFilterMapping,
//   roleMappings,
//   statusFilterMappings,
//   vaccinationRecordFilterMapppings
// } from "./mappings";
// import UserStatusCell from "../UserStatusCell";
// import UserVaccinationRecordCell from "../UserVaccinationRecordCell";

// columns to pass to table
export const columns = (userSegmentValues, permission, guardianMode, isSAFEPassportEnabled = false) => ({
  active: [
    {
      id: 'dependent',
      label: ' ',
      show: false,
      sortable: false,
      minWidth: 70,
      cell: UsersParentCell,
    },
    {
      id: 'selected',
      title: 'Select All',
      show: true,
      minWidth: 65,
      maxWidth: 70,
      sortable: false,
      headerCell: SelectionHeaderCell,
    },
    {
      id: 'firstName',
      label: 'First Name',
      show: true,
      minWidth: 170,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      show: true,
      minWidth: 150,
    },
    // {
    //   id: "firstTimeLogin",
    //   label: "Status",
    //   sortable: true,
    //   show: true,
    //   minWidth: 110,
    //   cell: htmlUtils.withMapping(htmlUtils.tag, statusMappings),
    // },
    // // SAF-4762 - Remove unnecessary columns in people list
    // {
    //   id: 'status',
    //   label: 'Status',
    //   sortable: true,
    //   show: false,
    //   minWidth: 170,
    //   options: statusFilterMappings,
    //   // cell: htmlUtils.withMapping(htmlUtils.tag, statusMappings),
    //   cell: UserStatusCell,
    //   labelField: 'label',
    //   valueField: 'id',
    //   filter: true,
    // },
    // SHP-4299 - Remove unnecessary columns in people list
    // {
    //   id: 'guardianMode',
    //   label: 'Guardian',
    //   show: guardianMode == true,
    //   sortable: false,
    //   minWidth: 110,
    //   cell: UsersParentCell,
    // },
    {
      id: 'email',
      label: 'Email',
      show: true,
      minWidth: 250,
    },
    {
      id: 'birthDate',
      label: 'Birth Date',
      show: !permission || permission !== 'ADMINISTRATOR_RESTRICTED',
      minWidth: 150,
    },
    {
      id: 'gender',
      label: 'Biological Sex',
      show: !permission || permission !== 'ADMINISTRATOR_RESTRICTED',
      minWidth: 170,
    },
    // // SAF-4762 - Remove unnecessary columns in people list
    // {
    //   id: 'mostRecentTest',
    //   label: 'Most Recent Test',
    //   show: enableVaccinationRecord && (!permission || permission !== 'ADMINISTRATOR_RESTRICTED'),
    //   sortable: true,
    //   minWidth: 170,
    //   cell: UserRecentTestCell,
    // },
    // {
    //   id: 'lastTestResult',
    //   label: 'Test Results',
    //   show: false,
    //   minWidth: 170,
    //   filter: true,
    //   options: lastTestResultFilterMapping,
    //   labelField: 'label',
    //   valueField: 'id',
    //   multiSelectFilter: true,
    // },
    // // Vaccination Record
    // {
    //   id: 'vaccineRecordStatus',
    //   label: 'Vaccination Record',
    //   show: false,
    //   minWidth: 220,
    //   filter: !permission || permission !== 'ADMINISTRATOR_RESTRICTED',
    //   options: vaccinationRecordFilterMapppings,
    //   cell: UserVaccinationRecordCell,
    //   labelField: 'label',
    //   valueField: 'id',
    //   multiSelectFilter: true,
    // },
    {
      id: 'complianceStatus',
      label: 'Compliance',
      show: isSAFEPassportEnabled,
      minWidth: 180,
      sort: true,
      // cell: htmlUtils.withMapping(htmlUtils.tag, complianceMapping),
      cell: UserComplainceCell,
    },
    // // SAF-4762 - Remove unnecessary columns in people list
    // {
    //   id: 'covidVaccineStatus',
    //   label: 'Covid Vaccine Status',
    //   show: false,
    //   minWidth: 170,
    //   labelField: 'label',
    //   valueField: 'id',
    // },
    // {
    //   id: 'permission',
    //   label: 'Role',
    //   show: false,
    //   filter: true,
    //   options: roleMappings,
    //   labelField: 'label',
    //   valueField: 'id',
    // },
    // // SHP-4299 - Remove unnecessary columns in people list
    // {
    //   id: 'gender',
    //   label: 'Sex',
    //   show: false,
    //   filter: !permission || permission !== 'ADMINISTRATOR_RESTRICTED',
    //   options: genderMappings,
    //   labelField: 'label',
    //   valueField: 'id',
    // },
    // {
    //   id: 'userSegment',
    //   label: 'User Segment',
    //   show: false,
    //   filter: true,
    //   options: userSegmentValues,
    // },
    {
      id: 'actions',
      label: 'Actions',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: UsersActionsCellWrapper,
    },
  ],
  invited: [
    {
      id: 'permissionValue',
      label: 'Role',
      show: true,
      sortable: false,
      minWidth: 250,
    },
    {
      id: 'email',
      label: 'Email',
      show: true,
      sortable: true,
      minWidth: 250,
    },
    {
      id: 'inviteDate',
      label: 'Invite Sent Date',
      show: true,
      sortable: true,
      minWidth: 250,
    },
    {
      id: 'actions',
      label: 'Actions',
      show: true,
      minWidth: 100,
      sortable: false,
      cell: UsersActionsCellWrapper,
    },
  ],
});

export const tableButtons = (currentTenant, setUserCreationDialogOpen) => [
  {
    title: 'Import Users',
    // button types: link (will redirect to new page), dialog (will open dialog)
    buttonType: 'link',
    // if buttonType === 'link', provide link
    link: `/${currentTenant && currentTenant.id}/users/import`,
    // button variants: contained, outlined
    variant: 'outlined',
  },
  {
    title: 'New User',
    buttonType: 'action',
    // link: `/${currentTenant && currentTenant.id}/users/create`,
    action: () => {
      setUserCreationDialogOpen(true);
    },
    variant: 'contained',
  },
];

export const allUploadsColumns = () => [
  {
    id: 'fileName',
    label: 'File Name',
    show: true,
    sortable: false,
    minWidth: 250,
  },
  {
    id: 'uploadedBy',
    label: 'Uploaded By',
    show: true,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'dateUploaded',
    label: 'Date Uploaded',
    show: true,
    sortable: false,
    minWidth: 110,
  },
  {
    id: 'dependent',
    label: 'Download',
    show: true,
    sortable: false,
    minWidth: 110,
    cell: UploadActionCell,
  },
];
