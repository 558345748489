import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';

const initialState = {
  error: null,
  notificationLoading: false,
  latestConsult: null,
  workflowNotificaitonLoading: false,
  consultDetails: null,
};

// SteadyMD SIMULATOR
const steadyMDSimulatorSlice = createSlice({
  name: 'steadyMD',
  initialState,
  reducers: {
    resetNetsuiteLoading: (state) => {
      state.notificationLoading = 'idle';
    },
    resetWorkflowNotification: (state) => {
      state.workflowNotificaitonLoading = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(consultStatusNotificaiton.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.notificationLoading = 'pending';
      }
    });
    builder.addCase(consultStatusNotificaiton.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.notificationLoading = 'fulfilled';
      }
    });

    builder.addCase(consultWorkflow.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.workflowNotificaitonLoading = 'pending';
      }
    });
    builder.addCase(consultWorkflow.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.workflowNotificaitonLoading = 'fulfilled';
      }
    });
    builder.addCase(consultWorkflow.rejected, (state) => {
      state.workflowNotificaitonLoading = 'rejected';
    });

    builder.addCase(fetchLatestConsult.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.notificationLoading = 'pending';
      }
    });
    builder.addCase(fetchLatestConsult.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.notificationLoading = 'fulfilled';
        state.latestConsult = action.payload?.[0]?.data;
      }
    });
    builder.addCase(fetchLatestConsult.rejected, (state) => {
      state.notificationLoading = 'rejected';
    });

    builder.addCase(getConsultDetails.pending, (state, action) => {
      if (action.meta.requestStatus === 'pending') {
        state.notificationLoading = 'pending';
      }
    });
    builder.addCase(getConsultDetails.fulfilled, (state, action) => {
      if (action.meta.requestStatus === 'fulfilled') {
        state.notificationLoading = 'fulfilled';
        state.consultDetails = action.payload?.[0]?.data;
      }
    });
    builder.addCase(getConsultDetails.rejected, (state) => {
      state.notificationLoading = 'rejected';
    });
  },
});

export const consultStatusNotificaiton = createAsyncThunk(
  'steadyMDySimulator/consultStatusNotificaiton',
  async (payload, { rejectWithValue }) => {
    try {
      const { event, formData } = payload;
      const result = await api.postSimulateSteadyMDNotification(event, formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const consultWorkflow = createAsyncThunk(
  'steadyMDySimulator/consultWorkflow',
  async (payload, { rejectWithValue }) => {
    try {
      const { formData } = payload;
      const result = await api.postSimulateSteadyMDWorkflow(formData);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLatestConsult = createAsyncThunk(
  'steadyMDySimulator/fetchLatestConsult',
  async (payload, { rejectWithValue }) => {
    try {
      const { userId, tenantId } = payload;
      const result = await api.getLastestConsult(tenantId, userId);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getConsultDetails = createAsyncThunk(
  'steadyMDySimulator/getConsultDetails',
  async (payload, { rejectWithValue }) => {
    try {
      const { userId, tenantId, consultId } = payload;
      const result = await api.getConsultDetails(tenantId, userId, consultId);

      return result;
    } catch (error) {
      if (!error.response) {
        throw new Error(error);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const {
  resetDoordashLoading,
  resetNetsuiteLoading,
  resetWelcomeKitLoading,
  resetWelcomeKitData,
  resetWorkflowNotification,
} = steadyMDSimulatorSlice.actions;

export default steadyMDSimulatorSlice.reducer;
