// redux
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from '../../constants';
import { selectTenantNetwork } from '../Networks/selectors';
import { resetForm } from '../Shared/Forms/formSlice';
import { selectFormData } from '../Shared/Forms/selectors';
// kendo
import { selectCurrentTenant } from '../Shared/selectors';
import AppKendoTableWrapper from '../Shared/Table/KendoTableWrapper';
import utils from '../Shared/Utils/utils';

import {
  getMasterCode,
  clearLatestVaxRecord,
  clearVaxRecords,
} from '../Shared/VaccinationRecord/vaccinationRecordSlice';
import {
  covidVaxStatusMapping,
  vaccinationRecordMappings,
  lastTestResultMapping,
  initialKendoState,
} from './helpers/mappings';
import { columns, tableButtons } from './helpers/usersListMapper';
import { hasLastTestResult } from './helpers/vaccinationRecords';
import {
  selectAllUsers,
  selectAllUsersLoading,
  selectCurrentUserPermissions,
  selectPrevFilterState,
  selectCheckboxAllUsers,
  selectCheckboxUserIds,
  selectIsSelectAllTriggered,
  selectTenantUserTotalsLoading,
} from './selectors';
import SendMessageDialog from './SendMessageDialog';
import UserCreationDialog from './UserCreationDialog';
import {
  getMetadataValues,
  getUsers,
  setUserPaginationState,
  setPrevFilterState,
  setSelectAllUsers,
  setSelectUserIds,
  setIsSelectAllTriggered,
} from './usersSlice';

function ActiveUserList({ userType }) {
  const currentNetwork = useSelector(selectTenantNetwork);
  const userTotals = useSelector(selectTenantUserTotalsLoading);
  const allUsersLoading = useSelector(selectAllUsersLoading);
  const allUsers = useSelector(selectAllUsers);
  const currentTenant = useSelector(selectCurrentTenant);
  const currentUser = useSelector(selectCurrentUserPermissions);
  const formData = useSelector(selectFormData) || {};
  const tenantSetting = useSelector(selectTenantNetwork);
  const prevFilterState = useSelector(selectPrevFilterState);

  const [userSegmentValues, setUserSegmentValues] = useState([]);
  const [userCreationDialogOpen, setUserCreationDialogOpen] = useState(false);
  const [sendMessageDialogOpen, setsendMessageDialogOpen] = useState(false);
  const [kendoPaginationState, setKendoPaginationState] = useState(initialKendoState);
  const selectIds = useSelector(selectCheckboxUserIds);
  const selectAll = useSelector(selectCheckboxAllUsers);

  const [memberData, setMemberData] = useState({});
  const dispatch = useDispatch();
  const [isEnableDateFilter] = useState(true);
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState(kendoPaginationState.search);
  const [showUserFilter, setShowUserFilter] = useState(false);
  const isSelectAllTriggered = useSelector(selectIsSelectAllTriggered);

  //
  useEffect(() => {
    dispatch(setSelectUserIds([]));
  }, []);

  // Place Kendo state into the store so it is available in actions
  useEffect(() => {
    dispatch(setUserPaginationState(kendoPaginationState));
  }, [kendoPaginationState]);

  // handles all table updates from table wrapper
  // Added a debounce when filtering the User List
  const handleTableUpdate = debounce((page, pageSize, sort, direction, searchValue, filter, tags, isClearFilter) => {
    // Added default sort
    if (!sort) {
      sort = 'created';
      direction = 'DESC';
    }
    let sortValue = sort;
    if (sort === 'state') {
      sortValue = 'primaryAddress.state';
    } else if (sort === 'firstTimeLogin') {
      sortValue = 'settings_firstTimeLogin';
    }
    setKendoPaginationState({
      sort: [{ field: sort, dir: direction }],
      take: pageSize,
      skip: page,
      search: searchValue,
      filter,
    });

    // [SAF-6139] clears searchState when search bar is cleared
    if (searchValue.length === 0) {
      setSearchState('');
    }

    // [SAF-2546] avoids throwing error during initial state of searchBar
    const searchStr = searchValue?.trim() ?? '';

    // [SHP-3552] - If Updating the Previous Filter State
    if (Object.keys(prevFilterState).length !== 0 && !isClearFilter) {
      dispatch(
        setPrevFilterState({
          ...prevFilterState,
          ...filter,
          searchValue: searchStr, // avoids
        })
      );
    } else {
      // Initial filter state or Reset Filter
      dispatch(
        setPrevFilterState({
          ...filter,
          searchValue: searchStr,
        })
      );
    }

    dispatch(
      getUsers(
        currentTenant.id,
        page,
        pageSize,
        searchStr,
        sortValue,
        direction.toUpperCase(),
        filter?.permission?.id,
        filter?.gender?.id,
        filter?.userSegment,
        filter?.status?.id,
        // SHP-3161
        filter?.vaccineRecordStatus,
        filter?.lastTestResult,
        filter?.dateRangeFilter
      )
    );
  }, 500);

  useEffect(() => {
    const prevPageKey = 'PREVIOUS_PAGE';
    const memberPageVal = 'MEMBER_PAGE';
    const prevPage = localStorage.getItem(prevPageKey, memberPageVal);

    // SHP-4671 - fix reloading issue when clicking the Select All
    if (isSelectAllTriggered) {
      dispatch(setIsSelectAllTriggered(false));
      setShowUserFilter(Object.keys(prevFilterState).length !== 0 ?? false);
      return;
    }

    /* Start saving Previous Filter State */
    if (prevPage === memberPageVal) {
      // clear previousPage state
      localStorage.setItem(prevPageKey, undefined);

      if (prevFilterState?.searchValue) {
        setSearchState(prevFilterState?.searchValue);
      }

      /* SHP-3552 - Use Previous Filter State */
      setShowUserFilter(prevFilterState?.showUserFilter ?? false);

      dispatch(
        getUsers(
          currentTenant.id,
          kendoPaginationState?.skip,
          kendoPaginationState?.take,
          prevFilterState?.searchValue ?? '',
          kendoPaginationState?.sort[0]?.field,
          kendoPaginationState.sort[0]?.dir?.toUpperCase(),
          prevFilterState?.permission?.id,
          prevFilterState?.gender?.id,
          prevFilterState?.userSegment,
          prevFilterState?.status?.id,
          prevFilterState?.vaccineRecordStatus,
          prevFilterState?.lastTestResult,
          prevFilterState?.dateRangeFilter
        )
      );
    } else {
      /* Use initial Kendo table state */
      setShowUserFilter(prevFilterState?.showUserFilter ?? false);
      dispatch(setPrevFilterState({}));

      dispatch(
        getUsers(
          currentTenant.id,
          kendoPaginationState?.skip,
          kendoPaginationState?.take,
          kendoPaginationState?.search,
          kendoPaginationState?.sort[0]?.field,
          kendoPaginationState.sort[0]?.dir?.toUpperCase(),
          kendoPaginationState?.filter
        )
      );
    }

    dispatch(getMasterCode({ term: 'covid', top: 10 }));
    dispatch(clearLatestVaxRecord());
    dispatch(clearVaxRecords());
    /* eslint-disable-next-line */
    (async function () {
      setUserSegmentValues(await dispatch(getMetadataValues(currentTenant.id, 'User Segment')));
    })();

    return () => {
      // Clear Previous Page name when leaving User Page
      localStorage.setItem(prevPageKey, undefined);
    };
  }, [currentTenant, getMasterCode, clearLatestVaxRecord, clearVaxRecords]);

  // onRowClick function in table
  const handleRowClick = useCallback(
    (e) => {
      navigate(paths.profileDetails(currentTenant.id, e.email));
    },
    [currentTenant]
  );

  // Filters to pass to table wrapper
  const columnList = useMemo(
    () =>
      columns(
        userSegmentValues,
        currentUser && currentUser.permission,
        tenantSetting && tenantSetting.requireGuardianMode,
        currentNetwork?.isSAFEPassportEnabled,
        currentNetwork?.verifyVaccinationSettings?.enableVaccinationRecord
      )[userType],
    [currentUser, userSegmentValues]
  );
  const filters = columnList && columnList.filter((c) => c.filter);
  // Buttons to pass to table wrapper - must contain title, button type and variant type
  const buttons = tableButtons(currentTenant, setUserCreationDialogOpen);

  const data = {
    total: allUsers && allUsers.total,
    pageSize: allUsers && allUsers.pageSize,
    items:
      allUsers &&
      allUsers.items &&
      allUsers.items.map((user) => {
        const b = {
          ...user,
          birthDate: user.birthDate ? utils.formatDate(user.birthDate) : null,
          permission: user.metadata && user.metadata._PERMISSION,
          userSegment: user.metadata && user.metadata['User Segment'],
          status: user.status,
          gender: user.gender && utils.removeUnderscoresTitleCase(user.gender),
          covidVaccineStatus:
            user.vaccineStatus &&
            covidVaxStatusMapping.find(
              (x) => x.value === user.vaccineStatus.find((x) => x.vaccinationGroupName == 'COVID19').vaccStatus
            ).value,
          firstTimeLogin: user.settings.firstTimeLogin ? 'INVITED' : 'ACTIVE',
          // Vaccination Record Status & Most Recent Test
          vaccineRecordStatus:
            user.lastVaccinationRecordResults?.COVID19?.status ?? vaccinationRecordMappings.NOT_AVAILABLE.value,
          mostRecentTest:
            user.infectionTestDates &&
            hasLastTestResult(user.infectionTestDates) &&
            // Sort Recent Test Dates in Descending order, and get the most recent one
            utils.formatDate(
              user.infectionTestDates.sort((a, b) => new Date(b.lastTest) - new Date(a.lastTest))[0].lastTest
            ),
          lastTestResult: user.infectionTestResults?.COVID19 ?? lastTestResultMapping.UNAVAILABLE.value,
          selected: false,
          inviteDate: utils.formatDisplayDate(user.created),
        };
        return b;
      }),
  };

  const onCloseDialog = () => {
    setUserCreationDialogOpen(false);
    dispatch(resetForm());
  };
  const onCloseSendMessage = () => {
    setsendMessageDialogOpen(false);
  };

  // on click of row/row checkbox, select/unselect item if not already in selected
  const selectionChange = (dataItem) => {
    const newSelectIds = [...selectIds];
    const index = selectIds.indexOf(dataItem && dataItem._id);
    if (index === -1) {
      newSelectIds.push(dataItem._id);
    } else {
      newSelectIds.splice(index, 1);
    }
    // setSelectIds(newSelectIds);
    dispatch(setSelectUserIds(newSelectIds));
  };

  // on click of select all, select/unselect all items on page
  const headerSelectionChange = (event) => {
    let newSelectIds = [...selectIds];
    const { checked } = event.syntheticEvent.target;
    if (selectAll) {
      newSelectIds = !newSelectIds.length ? data.items.map((item) => item._id) : [];
    } else if (!checked) {
      newSelectIds = newSelectIds.filter((id) => data.items.map((item) => item._id).indexOf(id) === -1);
    } else {
      newSelectIds = [
        ...newSelectIds,
        ...data.items.filter((item) => newSelectIds.indexOf(item._id) === -1).map((item) => item._id),
      ];
    }

    // setSelectIds(newSelectIds);
    dispatch(setSelectUserIds(newSelectIds));

    // setData(ids);
  };
  const handleSelectAll = (value) => {
    dispatch(setIsSelectAllTriggered(true));
    dispatch(setSelectUserIds([]));
    dispatch(setSelectAllUsers(value));

    setShowUserFilter(true);
  };

  const handleBulkAction = (value) => {
    setsendMessageDialogOpen(true);

    let memberData = {};

    if (Object.keys(prevFilterState).length !== 0) {
      memberData = {
        ...(selectAll && {
          filter: {
            ...(prevFilterState?.permission?.id && {
              permission: prevFilterState?.permission?.id,
            }),
            ...(prevFilterState?.gender && { gender: prevFilterState?.gender }),
            ...(prevFilterState?.searchValue && { searchParam: prevFilterState?.searchValue }),
            ...(prevFilterState?.userSegment && {
              userSegment: prevFilterState?.userSegment,
            }),
            ...(prevFilterState?.lastTestResult && {
              lastTestResult: prevFilterState?.lastTestResult,
              ...(prevFilterState?.status && {
                status: prevFilterState?.status?.id,
              }),
              ...(prevFilterState?.vaccineRecordStatus && {
                vaccineRecordStatus: prevFilterState?.vaccineRecordStatus,
              }),
              ...(prevFilterState?.dateRangeFilter && {
                dateRangeFilter: prevFilterState?.dateRangeFilter,
              }),
            }),
          },
          excludeUserIds: value.ids,
        }),
        ...(value.ids && !selectAll && { userIds: value.ids }),
      };
    } else {
      memberData = {
        ...(selectAll && {
          filter: {
            ...(value.filter.permission && {
              permission: value.filter.permission.id,
            }),
            ...(value.filter.gender && { gender: value.filter.gender.id }),
            ...(value.searchParam && { searchParam: value.searchParam }),
            ...(value.filter.userSegment && {
              userSegment: value.filter.userSegment,
            }),
          },
          excludeUserIds: value.ids,
        }),
        ...(value.ids && !selectAll && { userIds: value.ids }),
      };
    }

    setMemberData(memberData);
  };
  const handleShowUserFilter = (isOpenUserFilter) => {
    dispatch(
      setPrevFilterState({
        ...prevFilterState,
        showUserFilter: isOpenUserFilter,
      })
    );

    setShowUserFilter(isOpenUserFilter);
  };

  const setResetSelectUserIds = (value) => {
    dispatch(setSelectUserIds(value));
  };

  return (
    <>
      <AppKendoTableWrapper
        data={data}
        sortingMode="single"
        initialSort={initialKendoState.sort[0].field}
        initialDir={initialKendoState.sort[0].dir}
        searchInState={searchState}
        showSearchbar
        searchAdornmentPos="end"
        showFilterIcon
        showFilterClearIcon
        showButtons
        filters={filters}
        prevFilterState={prevFilterState}
        tableButtons={buttons}
        columns={columnList}
        currentTenant={currentTenant}
        loading={allUsersLoading || userTotals}
        onRowClick={handleRowClick}
        onTableUpdate={handleTableUpdate}
        disableAutoFocus
        showBulkAction
        onSelectionChange={selectionChange}
        onHeaderSelectionChange={headerSelectionChange}
        selectIds={selectIds}
        onSelectAll={handleSelectAll}
        onBulkAction={handleBulkAction}
        selectAll={selectAll}
        isEnableDateFilter={isEnableDateFilter}
        dateRangeFormat={'MMM dd, yyyy'}
        dateRangeFormatPlaceholder={{day: 'dd', month: 'mm', year: 'yyyy'}}
        setSelectIds={setResetSelectUserIds}
        isEnableSelectAllMessage
        showOpenFilters={showUserFilter}
        handleShowOpenFilters={handleShowUserFilter}
      />
      {userCreationDialogOpen && <UserCreationDialog closeDialog={onCloseDialog} formData={formData} />}
      {sendMessageDialogOpen && <SendMessageDialog closeDialog={onCloseSendMessage} data={memberData} />}
    </>
  );
}

export default ActiveUserList;
