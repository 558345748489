/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';

import account from '../../Account/accountSlice';
import accountPermissions from '../../Account/Permissions/permissionSlice';
import tenants from '../../Account/TenantManagement/tenantsSlice';
import alerts from '../../Alerts/alertsSlice';
import auth from '../../Auth/authSlice';
import accessToken from '../../ClinicalInformation/slices/accessTokenSlice';
import allergyList from '../../ClinicalInformation/slices/allergyListSlice';
import ccdViewer from '../../ClinicalInformation/slices/ccdViewerSlice';
import cerner from '../../ClinicalInformation/slices/cernerSlice';
import conditionList from '../../ClinicalInformation/slices/conditionListSlice';
import dosespot from '../../ClinicalInformation/slices/dosespotSlice';
import epic from '../../ClinicalInformation/slices/epicSlice';
import ePrescribe from '../../ClinicalInformation/slices/ePrescribeSlice';
import immunizationList from '../../ClinicalInformation/slices/immunizationListSlice';
import medicationList from '../../ClinicalInformation/slices/medicationListSlice';
import prescriptionList from '../../ClinicalInformation/slices/prescriptionListSlice';
import procedureList from '../../ClinicalInformation/slices/procedureList';
import vitalList from '../../ClinicalInformation/slices/vitalListSlice';
import xmlViewer from '../../ClinicalInformation/slices/xmlViewerSlice';
import confirmation from '../../Confirmation/confirmationSlice';
import contactSupport from '../../ContactSupport/helpers/ContactSupportSlice';
import content from '../../Content/contentSlice';
import dashboard from '../../Dashboard/dashboardSlice';
import legacyDashboard from '../../Dashboard/legacyDashboardSlice';
import diagnosticReport from '../../DiagnosticReport/diagnosticReportSlice';
import publicHealthReport from '../../DiagnosticReport/publicHealthReportSlice';
import encounters from '../../Encounters/encountersSlice';
import images from '../../Image/imagesSlice';
import language from '../../LanguageMenu/languageSlice';
import labTests from '../../MedicalCenters/labTestsSlice';
import tenantLabTests from '../../MedicalCenters/tenantLabTestsSlice';
import networks from '../../Networks/networksSlice';
import notification from '../../Notifications/notificationSlice';
import orders from '../../Orders/ordersSlice';
import rapidTest from '../../RapidTest/rapidTestSlice';
import providerList from '../../RecordRequest/providerListSlice';
import recordRequest from '../../RecordRequest/recordRequestSlice';
import reports from '../../Reporting/reportsSlice';
import settings from '../../Settings/settingsSlice';
import fvSimulator from '../../Simulator/slice/fvSimulatorSlice';
import fvSubsSimulator from '../../Simulator/slice/fvSubsSimulatorSlice';
import shopifySimulator from '../../Simulator/slice/shopifySimulatorSlice';
import steadyMDSimulator from '../../Simulator/slice/steadyMDSimulatorSlice';
import users from '../../Users/usersSlice';
import { monitoring } from '../../VirtualConsult/Monitoring/MonitoringReducer';
import reviewer from '../../VirtualConsult/Reviewer/reviewerSlice';
import questionnaire from '../../VirtualConsult/vcb-package/questionnaireSlice';
import virtualConsult from '../../VirtualConsult/virtualConsultSlice';
import consents from '../Consent/consentsSlice';
import form from '../Forms/formSlice';
import typeahead from '../Forms/KendoFields/Typeahead/typeaheadSlice';
import shared from '../sharedSlice';
import vaccinationRecords from '../VaccinationRecord/vaccinationRecordSlice';

// Root Reducer
const reducer = combineReducers({
  vaccinationRecords,
  notification,
  confirmation,
  users,
  shared,
  alerts,
  orders,
  settings,
  networks,
  content,
  tenants,
  account,
  labTests,
  tenantLabTests,
  form,
  virtualConsult,
  monitoring,
  accountPermissions,
  reports,
  diagnosticReport,
  publicHealthReport,
  allergyList,
  conditionList,
  medicationList,
  prescriptionList,
  immunizationList,
  vitalList,
  procedureList,
  consents,
  ccdViewer,
  xmlViewer,
  recordRequest,
  typeahead,
  ePrescribe,
  providerList,
  accessToken,
  reviewer,
  questionnaire,
  cerner,
  epic,
  rapidTest,
  dashboard,
  legacyDashboard,
  language,
  encounters,
  images,
  auth,
  contactSupport,
  fvSimulator,
  fvSubsSimulator,
  shopifySimulator,
  dosespot,
  steadyMDSimulator,
});

const rootReducer = (state, action) => {
  if (action.type === 'shared/setLoggedOut') {
    state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
